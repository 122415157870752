<template>
    <div class="start-block">
        <h3>Начало работы с Legal Dozor</h3>
        <p class="start-block__text secondary-text">
            Настройте мониторинг контрагента за 3 шага и быстро определяйте риски при работе
        </p>

        <div class="start-block__cards">
            <StartCard title="1. Добавьте контрагентов удобным для вас cпособом">
                <img class="start-card__image" src="../../images/start-image-1.webp" />
            </StartCard>
            <StartCard title="2. Настройте специализированные списки под свои задачи">
                <img class="start-card__image" src="../../images/start-image-2.webp" />
            </StartCard>
            <StartCard title="3. Отслеживайте каждое изменение онлайн">
                <img class="start-card__image" src="../../images/start-image-3.webp" />
            </StartCard>
        </div>
    </div>
</template>

<script>
import StartCard from "./StartCard.vue";

export default {
    name: "StartBlock",
    components: { StartCard },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.start-block {
    background: #0b1a38;
    padding: 115px 0 328px;
    text-align: center;
    color: #ffffff;
    @include block-padding;

    & h3 {
        margin-bottom: 42px;
    }
}

.start-block__text {
    margin-bottom: 268px;
}

.start-block__cards {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.start-card__image {
    position: absolute;
    width: 95%;
    bottom: 40%;
    left: 2.5%;
}

@media screen and (max-width: 1079.99px) {
    .start-block__text {
        margin-bottom: 180px;
    }
}

@media screen and (max-width: 899.99px) {
    .start-block__cards {
        flex-direction: column;
        gap: 50px;

        & > div {
            width: 100%;
            padding-left: 100px;
            padding-right: 100px;

            & img {
                width: 100%;
                position: relative;
                margin-top: -170px;
            }
        }
    }

    .start-block__text {
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 549.99px) {
    .start-block__cards {
        & > div {
            padding-left: 42px;
            padding-right: 42px;
        }
    }
}
</style>
