<template>
    <header class="header">
        <LogoComponent id="header-logo" />

        <el-dropdown class="header-dropdown" placement="bottom-start" trigger="click">
            <div class="header-burger">
                <img src="../images/burger-icon.svg" />
            </div>

            <template #dropdown>
                <el-dropdown-menu ref="dropdown">
                    <div class="dropdown__logo">
                        <LogoComponent />
                    </div>

                    <el-dropdown-item v-for="el in menu" :key="el.id" @click="smoothScroll(el.link)">
                        {{ el.text }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>

        <div class="header__menu-wrap">
            <span v-for="el in menu" :key="el.id" @click="smoothScroll(el.link)">
                {{ el.text }}
            </span>
        </div>

        <a href="https://cabinet.legaldozor.ru" target="_blank">
            <ButtonUI type="tertiary" text="Личный кабинет" />
        </a>
    </header>
</template>

<script>
import LogoComponent from "./LogoComponent.vue";
import ButtonUI from "./ui/ButtonUI.vue";

export default {
    name: "HeaderComponent",
    components: { LogoComponent, ButtonUI },

    data() {
        return {
            menu: [
                {
                    id: 0,
                    text: "Главная",
                    link: "#main",
                },
                {
                    id: 1,
                    text: "Оповещения",
                    link: "#notifications",
                },
                {
                    id: 2,
                    text: "Сбор и анализ",
                    link: "#collecting",
                },
                {
                    id: 3,
                    text: "Источники",
                    link: "#sources",
                },
                {
                    id: 4,
                    text: "Тарифы",
                    link: "#tariffs",
                },
            ],
        };
    },

    methods: {
        smoothScroll(anchor) {
            const target = document.querySelector(anchor);
            if (!target) return;
            const headerHeight = 80;
            const offsetTop = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
            window.scroll({
                top: offsetTop,
                behavior: "smooth",
            });
        },

        closeDropdown() {
            this.$refs.dropdown.close(); // Вызываем метод hide() для скрытия Dropdown
        },
    },
};
</script>

<style lang="scss">
@import "../assets/scss/mixins.scss";

.header {
    position: sticky;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(11.5px);
    @include block-padding;
    z-index: 10;

    .button {
        font-size: 12px;
        padding: 8px 16px;
        height: 32px;
        border-radius: 8px;
    }
}

.header__menu-wrap {
    display: flex;
    align-items: center;
    gap: 48px;
    font-size: 14px;

    span {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
}

.header-dropdown {
    display: none;
}

.dropdown__logo {
    margin: 10px 20px 20px 10px;
}

@media screen and (max-width: 1023.99px) {
    .header__menu-wrap {
        display: none;
    }

    #header-logo {
        display: none;
    }

    .header-dropdown {
        display: block;
    }
}
</style>
