<template>
    <div class="organization-icon">
        <img src="../../../images/organizations/fedresurs.svg" />
    </div>
</template>

<script>
export default {
    name: "FedresursIcon",
};
</script>

<style lang="scss">
.organization-icon {
    width: 70px;
    display: flex;
    justify-content: center;
}
</style>
