<template>
    <div class="tariff-block" id="tariffs">
        <h1>Тарифы <span class="highlighted-text">Legal Dozor</span></h1>
        <p>
            При регистрации аккаунта в сервисе активируется пробный период. Чтобы расширить возможности и сохранить
            полный доступ к своим спискам и объектам мониторинга необходимо преобрести полный доступ.
        </p>

        <div class="tariff-block__content-wrap">
            <div class="tariff-block__image">
                <img src="../../images/tariff-image.webp" />
            </div>

            <div class="tariff-block__content">
                <div class="tariff-block__tariff-cards">
                    <TariffCard
                        v-for="el in cards"
                        :key="el.id"
                        :duration="el.duration"
                        :oldPrice="el.oldPrice"
                        :newPrice="el.newPrice"
                        :text="el.text"
                        :highlighted="el.highlighted"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RightArrowIcon from "@/components/icons/RightArrowIcon.vue";
import TariffCard from "@/components/tariff-block/TariffCard.vue";

export default {
    name: "TariffBlock",
    components: { RightArrowIcon, TariffCard },

    data() {
        return {
            cards: [
                {
                    id: 0,
                    duration: "1 месяц",
                    newPrice: "2 999 ₽",
                    text: "Полный доступ для тех, кто хочет получать самую полную и свежую информацию о бизнесе и физических лицах по любому из доступных каналов.",
                    highlighted: true,
                },
                {
                    id: 1,
                    duration: "3 месяца",
                    newPrice: "6 999 ₽",
                    oldPrice: "10 999 ₽",
                    highlighted: false,
                },
                {
                    id: 2,
                    duration: "6 месяца",
                    newPrice: "13 999 ₽",
                    oldPrice: "16 999 ₽",
                    highlighted: false,
                },
                {
                    id: 3,
                    duration: "12 месяца",
                    newPrice: "26 999 ₽",
                    oldPrice: "30 999 ₽",
                    highlighted: false,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.tariff-block {
    padding: 90px 0 100px;
    @include block-padding;
    background: #f5f7fa;

    & h1 {
        text-align: center;
        margin-bottom: 24px;
    }

    & > p {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 83px;
        max-width: 635px;
    }
}

.tariff-block__image {
    & img {
        width: 100%;
    }
}

.tariff-block__content-wrap {
    display: flex;
    gap: 91px;
}

.tariff-block__tariff-cards {
    & > div:not(:last-child) {
        border-bottom: 1px solid #c0c6d9;
    }
}

@media screen and (max-width: 859.99px) {
    .tariff-block__image {
        display: none;
    }

    .tariff-block__content-wrap {
        justify-content: center;
    }
}

@media screen and (max-width: 520px) {
    .tariff-block__tariff-cards {
        & .tariff-card {
            width: 100%;
        }
    }
}
</style>
