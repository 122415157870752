<template>
    <div>
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_1441_383357"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="19"
                height="19"
            >
                <path d="M18.3594 0.894043H0.359375V18.894H18.3594V0.894043Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1441_383357)">
                <path
                    d="M9.35938 0.894043C7.57935 0.894043 5.83929 1.42188 4.35925 2.41082C2.8792 3.39975 1.72565 4.80536 1.04446 6.44989C0.363275 8.09443 0.185045 9.90403 0.532312 11.6499C0.879579 13.3957 1.73675 14.9993 2.99542 16.258C4.25409 17.5167 5.85774 18.3738 7.60357 18.7211C9.3494 19.0684 11.159 18.8901 12.8035 18.209C14.4481 17.5278 15.8537 16.3742 16.8426 14.8942C17.8315 13.4141 18.3594 11.6741 18.3594 9.89404C18.3552 7.50838 17.4056 5.22163 15.7187 3.53471C14.0318 1.84779 11.745 0.898232 9.35938 0.894043ZM12.8611 8.83242L9.31184 12.3659C9.23486 12.4491 9.14182 12.5159 9.03834 12.5621C8.93486 12.6084 8.82308 12.6333 8.70973 12.6352C8.59147 12.6348 8.47447 12.6108 8.36563 12.5646C8.25679 12.5183 8.15829 12.4507 8.07593 12.3659L6.26959 10.5912C6.1057 10.4252 6.01446 10.2009 6.01594 9.96767C6.01743 9.7344 6.11152 9.51128 6.27751 9.34739C6.44351 9.1835 6.66781 9.09226 6.90107 9.09374C7.13434 9.09523 7.35746 9.18932 7.52135 9.35531L8.67804 10.4962L11.6094 7.56482C11.742 7.42399 11.9233 7.339 12.1164 7.32714C12.2503 7.31497 12.3852 7.33283 12.5113 7.37943C12.6374 7.42603 12.7515 7.50019 12.8453 7.59651C12.9285 7.67349 12.9953 7.76653 13.0416 7.87001C13.0879 7.97349 13.1127 8.08527 13.1147 8.19862C13.1152 8.31702 13.0916 8.43429 13.0452 8.54326C12.9989 8.65223 12.9309 8.75062 12.8453 8.83242"
                    fill="white"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: "CollectingIcon",
};
</script>

<style></style>
