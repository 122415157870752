<template>
    <div class="start-card">
        <slot />

        <p class="start-card__title">{{ title }}</p>
    </div>
</template>

<script>
export default {
    name: "PowerfulToolCard",
    props: {
        title: {
            type: String,
            default: "Заголовок",
            required: true,
        },
    },
};
</script>

<style lang="scss">
.start-card {
    width: 373px;
    background: #182949;
    border-radius: 20px;
    padding: 148px 30px 42px;
    position: relative;
}

.start-card__image {
    width: 100%;
    position: absolute;
}

.start-card__title {
    font-weight: 600;
}
</style>
