<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "App",
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* html {
    scroll-behavior: smooth;
} */

input {
    font-family: "Open Sans", sans-serif;
}

h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 57.5px;
}

h2 {
    font-size: 38px;
    font-weight: 600;
    line-height: 50px;
}

h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
}

p {
    font-size: 16px;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: inherit;
}

.el-popper__arrow {
    display: none;
}

.el-dropdown-menu {
    padding: 18px 14px;
    border-radius: 12px !important;

    & .el-dropdown-menu__item:not(:last-child) {
        margin-bottom: 10px;
    }
}

.el-dropdown-menu:hover {
    outline: none !important;
}

.el-dropdown-menu__item {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;

    &:hover {
        background-color: transparent !important;
        color: #5c5cff !important;
    }
}

.header-burger.el-tooltip__trigger.el-tooltip__trigger {
    cursor: pointer;
}

#app {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2a2b33;
    text-align: left;
}

.secondary-text {
    color: #d9d9d9;
}

.highlighted-text {
    color: #5c5cff;
}

.icon-list {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.text-link-wrap {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    &:hover div svg path {
        fill: #8a89fd !important;
    }
}

.text-link {
    color: #6867ff;
    font-weight: 600;
    font-size: 16px;

    &:hover {
        color: #8a89fd;
    }
}

.rules-page {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: calc(50% - 580px);
    padding-right: calc(50% - 580px);
}

.rules-page__header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
}

@media screen and (max-width: 1199.99px) {
    .rules-page {
        padding: 24px 20px;
    }
}
</style>
