<template>
    <div class="icon-list__element">
        <img :src="icon" />
        <p>{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: "IconListElement",
    props: {
        text: {
            type: String,
            default: "Текст по умолчанию",
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.icon-list__element {
    display: flex;
    align-items: center;
    gap: 16px;

    & p {
        font-weight: 600;
        text-transform: uppercase;
    }
}
</style>
