<template>
    <div class="start-action-block">
        <p class="start-action-block__subtitle">не верьте нам на слово</p>
        <h3>Начните мониторить с помощью Legal Dozor бесплатно</h3>

        <a href="https://cabinet.legaldozor.ru" target="_blank">
            <ButtonUI type="tertiary" text="Попробовать бесплатно" />
        </a>

        <p class="start-action-block__description">14-дневная бесплатная пробная версия</p>

        <div class="start-action-block__checked-elements">
            <div class="start-action-block__checked-el">
                <CheckedIcon />
                <p>Попробуйте, прежде чем покупать</p>
            </div>

            <div class="start-action-block__checked-el">
                <CheckedIcon />
                <p>Кредитная карта не требуется</p>
            </div>

            <div class="start-action-block__checked-el">
                <CheckedIcon />
                <p>Поддержка включена</p>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonUI from "@/components/ui/ButtonUI.vue";
import CheckedIcon from "../icons/CheckedIcon.vue";

export default {
    name: "StartActionBlock",
    components: { ButtonUI, CheckedIcon },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.start-action-block {
    border-radius: 10px;
    background: linear-gradient(155deg, #5c5cff 6%, #8960ff 82%);
    box-shadow: 0px 10px 35px 2px rgba(0, 0, 0, 0.16);
    padding: 84px 176px 20px;
    text-align: center;
    color: #ffffff;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: -200px;
    margin-bottom: 76px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .start-action-block__subtitle {
        font-size: 12px;
        margin-bottom: 24px;
        text-transform: uppercase;
    }

    & h3 {
        font-family: "Mulish", sans-serif;
        font-size: 34;
        margin-bottom: 42px;
    }

    .button {
        margin-bottom: 16px;
    }

    .start-action-block__description {
        font-size: 14px;
        margin-bottom: 78px;
    }
}

.start-action-block__checked-elements {
    display: flex;
    gap: 42px;
    align-items: center;
}

.start-action-block__checked-el {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 11px;

    & p {
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
    }
}

@media screen and (max-width: 1300px) {
    .start-action-block {
        border-radius: 0;
    }
}

@media screen and (max-width: 1024px) {
    .start-action-block {
        padding: 84px 20px 42px;
    }

    .start-action-block__checked-elements {
        flex-direction: column;
        gap: 20px;
    }
}
</style>
