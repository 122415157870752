<template>
    <div class="review-card">
        <p>{{ text }}</p>

        <div class="review-card__author-row">
            <div class="review-card__avatar-and-name">
                <slot name="avatar" />

                <div class="review-card__author-name-wrap">
                    <p class="review-card__name">{{ name }}</p>
                    <p class="review-card__job">{{ job }}</p>
                </div>
            </div>

            <slot name="company" />
        </div>
    </div>
</template>

<script>
export default {
    name: "ReviewCard",
    props: {
        text: {
            type: String,
            default: "Рыбный текст отзыва по умолчанию",
            required: true,
        },
        name: {
            type: String,
            default: "Имя Фамилия",
            required: true,
        },
        job: {
            type: String,
            default: "Должность",
            required: true,
        },
    },
};
</script>

<style lang="scss">
.review-card {
    width: 430px;
    padding: 25px;
    border-radius: 20px 20px 20px 0px;
    background: #f5f7fa;

    & > p {
        margin-bottom: 30px;
    }
}

.review-card__author-row {
    display: flex;
    justify-content: space-between;
}

.review-card__avatar-and-name {
    display: flex;
    gap: 12px;
}

.review-card__avatar {
    width: 45px;
    border-radius: 50%;
}

.review-card__author-name-wrap {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & p {
        font-size: 12px;
    }
}

.review-card__name {
    font-weight: 600;
}
</style>
