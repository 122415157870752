<template>
    <div class="rules-page">
        <div class="rules-page__header">
            <img src="../assets/logo-sign.svg" style="width: 44px" />
            <h2>Политика конфиденциальности</h2>
        </div>

        <p>
            Настоящее Соглашение заключается между ___________ (далее – Владелец) и любым лицом, становящимся после
            принятия условий данного соглашения пользователем сайта, расположенного в сети Интернет по адресу:
            __________________ (далее – Сайт), в дальнейшем именуемым «Пользователь», вместе по тексту Соглашения
            именуемые «Стороны», а по отдельности – «Сторона». В соответствии со статьей 435 Гражданского кодекса РФ
            настоящее Пользовательское соглашение признается офертой. В соответствии со статьей 438 Гражданского кодекса
            РФ безусловным принятием (акцептом) условий настоящего Соглашения считается факт регистрации на Сайте.
            Настоящее Соглашение, заключаемое путем акцепта настоящей оферты, не требует двустороннего подписания и
            действительно в электронном виде. Термины и определения, используемые в Соглашении В Соглашении, если из
            текста Соглашения прямо не вытекает иное, следующие слова и выражения будут иметь указанные ниже значения:
            1.1. Сайт – совокупность программных и аппаратных средств для ЭВМ, обеспечивающих публикацию для всеобщего
            обозрения информации и данных, объединенных общим целевым назначением, посредством технических средств,
            применяемых для связи между ЭВМ в сети Интернет. Сайт находится в сети Интернет по адресу:
            __________________. 1.2. Администратор/Владелец Сайта – ________________________________________________,
            расположенное по адресу: ________________________________________________. 1.3. Контентом Сайта признаются
            все объекты, размещенные на Сайте, в том числе элементы дизайна, текст, графические изображения,
            иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты и их подборки. Владелец Сайта
            является обладателем исключительных прав на использование Сайта, включая весь контент Сайта. 1.4.
            Пользователь – пользователь сети Интернет, в частности Сайта, имеющий свою личную страницу
            (профиль/аккаунт). 1.5. Профиль/аккаунт (Профиль) – личная страница Пользователя на Сайте, доступная всем
            Пользователям Сайта. 1.6. Учетная информация – уникальное имя Пользователя (логин) и пароль для входа на
            Сайт, указываемые Пользователем при регистрации на Сайте. 1.7. Модерация – просмотр Владельцем Сайта
            размещаемой (или планируемой к размещению – Премодерация) на Сайте Пользователем информации на предмет ее
            соответствия положениям настоящего Соглашения. Предмет Соглашения 2.1. Владелец Сайта оказывает Пользователю
            услуги по предоставлению доступа к сервисам Сайта, при этом обязательным условием оказания Владельцем Сайта
            услуг в соответствии с настоящим Соглашением является принятие, соблюдение Пользователем и применение к
            отношениям Сторон требований и положений, определенных настоящим Соглашением. 2.2. Владелец Сайта оставляет
            за собой право изменять условия настоящего Соглашения и всех его неотъемлемых частей без согласования с
            Пользователем с уведомлением последнего посредством размещения на Сайте новой редакции Соглашения или
            какой-либо его неотъемлемой части, подвергшейся изменениям. Принимая во внимание, что информационная
            рассылка может быть признана Сторонами спамом, Пользователь обязуется не менее одного раза в месяц
            знакомиться с содержанием Соглашения, размещенного на Сайте, в целях своевременного ознакомления с его
            изменениями. Новая редакция Соглашения и/или какой-либо его неотъемлемой части вступает в силу с момента
            опубликования на Сайте, если иной срок вступления изменений в силу не определен Владельцем Сайта при их
            опубликовании. Действующая редакция Соглашения и всех приложений к нему всегда находится на Сайте в
            публичном доступе по адресу: ____________________________. Права и обязанности Владельца Сайта 3.1. Владелец
            Сайта обязуется: 3.1.1. Оказывать Пользователю услуги, указанные в пункте 2.1 настоящего Соглашения. Доступ
            к Сайту предоставляется путем присвоения Пользователю Учетной информации в течение 1 (одного) рабочего дня с
            даты регистрации последнего по адресу __________________. 3.1.2. Предоставлять Пользователю возможность
            самостоятельно публиковать информацию о самом себе. 3.1.3. Не разглашать третьим лицам Учетную информацию
            Пользователя. 3.1.4. Обеспечивать круглосуточную доступность сервера, на котором расположен Сайт, за
            исключением времени проведения профилактических работ. 3.2. Владелец Сайта имеет право: 3.2.1. В случае
            нарушения Пользователем условий Соглашения направить Пользователю предупреждение, содержащее перечень
            нарушений. В случае если Пользователь не устранит нарушения в течение одного дня с момента направления ему
            уведомления или повторно нарушит указанные условия или иные условия, Владелец Сайта имеет право в
            одностороннем порядке отказаться от исполнения Соглашения, заблокировать доступ Пользователей к Профилю
            Пользователя и аннулировать Профиль Пользователя. 3.2.2. Осуществлять Модерацию в случаях, когда информация,
            размещаемая Пользователем, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому,
            половому, религиозному, социальному признакам; пропагандирует употребление наркотических и прочих средств,
            наносящих вред здоровью; призывает к бесчеловечному обращению с животными; нарушает права национальных и
            иных меньшинств; содержит призывы к насильственным действиям; нарушает права авторов и иных владельцев
            интеллектуальных прав. 3.2.3. Распоряжаться статистической информацией, связанной с функционированием Сайта,
            а также информацией Пользователей для обеспечения адресного показа рекламной информации различным аудиториям
            Пользователей Сайта. 3.2.4. Направлять Пользователю информацию о развитии Сайта и его сервисов; отправлять
            sms-сообщения с целью информирования о кодах активации, проведения опросов о качестве работы Сайта,
            предоставления и запросов прочей информации, а также рекламировать собственную деятельность и услуги. Права
            и обязанности Пользователя 4.1. Пользователь обязуется: 4.1.1. Полностью ознакомиться с условиями настоящего
            Соглашения до момента регистрации на Сайте. 4.1.2. Соблюдать все условия настоящего Соглашения. 4.1.3. Не
            передавать сведения о других Пользователях, полученные посредством Сайта, третьим лицам. 4.1.4. Не
            передавать свою Учетную информацию третьим лицам. 4.1.5. Не размещать на Сайте персональные данные других
            лиц, а также не использовать персональные данные других Пользователей или Пользователей каким-либо образом,
            не соответствующим требованиям законодательства РФ, в противоправных или противозаконных целях, в целях
            извлечения выгоды и любых иных целях, не соответствующих целям создания Сайта. 4.1.6. Не размещать в Профиле
            информацию и объекты (включая ссылки на них), которые могут нарушать права и интересы других лиц. 4.1.7. Не
            регистрироваться в качестве Пользователя от имени или вместо другого лица или регистрировать группу
            (объединение) лиц или юридическое лицо/индивидуального предпринимателя в качестве Пользователя. 4.1.8. Не
            загружать, не хранить, не публиковать, не распространять любую информацию, которая: – содержит угрозы,
            дискредитирует, оскорбляет, порочит честь и достоинство или деловую репутацию или нарушает
            неприкосновенность частной жизни других Пользователей или третьих лиц; – нарушает права несовершеннолетних
            лиц; – является вульгарной или непристойной, содержит нецензурную лексику, содержит порнографические
            изображения и тексты или сцены сексуального характера с участием несовершеннолетних; – содержит сцены
            насилия либо бесчеловечного обращения с животными; – содержит описание средств и способов суицида, любое
            подстрекательство к его совершению; – пропагандирует и/или способствует разжиганию расовой, религиозной,
            этнической ненависти или вражды, пропагандирует фашизм или идеологию расового превосходства; – содержит
            экстремистские материалы; – пропагандирует преступную деятельность или содержит советы, инструкции или
            руководства по совершению преступных действий; – содержит информацию ограниченного доступа, включая, но не
            ограничиваясь, государственную и коммерческую тайну, информацию о частной жизни третьих лиц; – содержит
            рекламу или описывает привлекательность употребления алкоголя и/или наркотических веществ, в том числе
            «цифровых наркотиков» (звуковых файлов, оказывающих воздействие на мозг человека за счет бинауральных
            ритмов), информацию о распространении наркотиков, рецепты их изготовления и советы по употреблению; – носит
            мошеннический характер; – а также нарушает иные права и интересы граждан и юридических лиц или требования
            законодательства РФ. 4.1.9. Не использовать программное обеспечение и не осуществлять действия, направленные
            на нарушение нормального функционирования Сайта и его сервисов или персональных страниц Пользователей, не
            загружать, не хранить, не публиковать, не распространять и не предоставлять доступ или иным образом
            использовать вирусы, трояны и другие вредоносные программы; не использовать без специального на то
            разрешения Владельца Сайта автоматизированные скрипты (программы) для сбора информации на Сайте и (или)
            взаимодействия с Сайтом и его сервисами. 4.1.10. Не пытаться получить доступ к логину и паролю другого
            Пользователя, в том числе включая, но не ограничиваясь, обман, взлом Профилей других Пользователей и прочее.
            4.2. Пользователю запрещено: 4.2.1. Осуществлять незаконные сбор и обработку персональных данных других
            Пользователей. 4.2.2. Осуществлять доступ к каким-либо услугам иным способом, кроме как через интерфейс,
            предоставленный Владельцем Сайта, за исключением случаев, когда такие действия были прямо разрешены
            Пользователю в соответствии с отдельным соглашением с Владельцем Сайта. 4.2.3. Воспроизводить, дублировать,
            копировать, продавать, осуществлять торговые операции и перепродавать услуги для каких-либо целей, за
            исключением случаев, когда такие действия были прямо разрешены Пользователю в соответствии с условиями
            отдельного соглашения с Владельцем Сайта. 4.2.4. Размещать коммерческую и политическую рекламу вне
            специальных разделов Сайта, установленных Владельцем Сайта. 4.2.5. Размещать любую информацию, которая, по
            мнению Владельца Сайта, является нежелательной, ущемляет интересы Пользователей или по другим причинам
            является нежелательной для размещения на Сайте. 4.3. Пользователь имеет право: 4.3.1. Круглосуточно получать
            доступ к серверу, на котором расположен Сайт, за исключением времени проведения профилактических работ.
            4.3.2. Самостоятельно изменять пароль, не уведомляя об этом Владельца Сайта. 4.3.3. Самостоятельно
            редактировать ранее размещенную о себе информацию на Сайте. 4.3.4. Обратиться в службу поддержки для
            блокирования своего Профиля (при отсутствии действующих демодоступов и подписок). 4.4. Пользователь согласен
            с тем, что, осуществляя доступ к Сайту и пользуясь его контентом, он: 4.4.1. Выражает свое безоговорочное
            согласие со всеми условиями настоящего Соглашения и обязуется их соблюдать или прекратить использование
            Сайта. 4.4.2. Получает личное неисключительное и непередаваемое право использовать контент Сайта на одном
            компьютере при условии, что ни сам Пользователь, ни любые иные лица при содействии Пользователя не будут
            копировать или изменять программное обеспечение; создавать программы, производные от программного
            обеспечения; проникать в программное обеспечение с целью получения кодов программ; осуществлять продажу,
            уступку, сдачу в аренду, передачу третьим лицам в любой иной форме прав в отношении программного обеспечения
            сервисов, предоставляемых Сайтом. 4.4.3. В целях реализации настоящего Соглашения Пользователи дают
            Владельцу Сайта разрешение на использование, хранение, обработку и распространение персональных данных тем
            способом и в той мере, в которой это необходимо для исполнения условий настоящего Соглашения. Порядок
            использования, хранения, обработки и распространения персональных данных Пользователей размещен на Сайте по
            адресу: _________________________________. Пользователь соглашается на передачу третьим лицам персональных и
            иных данных, в том числе для целей их обработки, для обеспечения функционирования Сайта, реализации
            партнерских и иных программ, при условии обеспечения в отношении передаваемых данных режима, аналогичного
            режиму, существующему на Сайте, в том числе включая, но не ограничиваясь, передачу персональных данных
            лицам, аффилированным с _________________________________ или заключившим с ними договоры, а также третьим
            лицам в случаях, когда такая передача необходима для использования Пользователем определенного сервиса либо
            для исполнения определенного соглашения или договора с Пользователем. Обработка персональных данных
            осуществляется в соответствии с политикой по обработке персональных данных ___________. Регистрация
            Пользователя 5.1. В целях пользования услугами, предоставляемыми Владельцем Сайта по настоящему Соглашению,
            Пользователь должен пройти обязательную процедуру регистрации на Сайте по адресу __________________.
            Регистрация Пользователя на Сайте является бесплатной, добровольной. 5.2. При регистрации на Сайте
            Пользователь обязан предоставить Владельцу Сайта необходимую достоверную и актуальную информацию для
            формирования Профиля, включая уникальные для каждого Пользователя логин (адрес электронной почты) и пароль
            доступа к Сайту, а также фамилию и имя. Регистрационная форма Сайта может запрашивать у Пользователя
            дополнительную информацию. 5.3. После предоставления информации, указанной в пункте 5.2 настоящего
            Соглашения, Пользователю необходимо подтвердить регистрацию путем выражения своего желания через нажатие на
            ссылку подтверждения регистрации в сообщении, отправленном на указанную им электронную почту. 5.4. По
            завершении процесса регистрации Пользователь становится обладателем Учетной информации Пользователя, что
            означает, что он несет ответственность за безопасность Учетной информации, а также за все, что будет сделано
            на Сайте под Учетной информацией Пользователя. Пользователь обязан немедленно уведомить Владельца Сайта о
            любом случае несанкционированного доступа к Сайту, то есть осуществленного третьим лицом без согласия и
            ведома Пользователя и/или о любом нарушении безопасности Учетной информации Пользователя. Владелец Сайта не
            несет ответственности за возможную потерю или порчу данных, которая может произойти из-за нарушения
            Пользователем положений настоящего пункта Соглашения. 5.5. Для начала работы с Сайтом Пользователь должен
            ввести в соответствующем разделе Сайта свою Учетную информацию. 5.6. Если Пользователем не доказано
            обратное, любые действия, совершенные с использованием его логина и пароля, считаются совершенными
            соответствующим Пользователем. В случае несанкционированного доступа к логину и паролю и/или Профилю
            Пользователя или распространения логина и пароля Пользователь обязан незамедлительно сообщить об этом
            Владельцу Сайта в установленном порядке. Ответственность Сторон 6.1. Пользователь самостоятельно определяет
            перечень организационных и программных (для ЭВМ) средств для сохранения в тайне своей Учетной информации и
            обеспечения санкционированного доступа к ней. Владелец Сайта не несет ответственность за убытки, причиненные
            Пользователю в результате разглашения третьим лицам Учетной информации Пользователя, произошедшего не по
            вине Владельца Сайта. Если любое лицо, помимо Пользователя, авторизуется на Сайте, используя Учетную
            информацию Пользователя, то все действия, совершенные таким лицом, будут считаться совершенными этим
            Пользователем. Пользователь самостоятельно несет ответственность за все действия, совершенные им на Сайте, а
            также за все действия, совершенные на Сайте любыми иными лицами с использованием Учетной информации
            Пользователя. 6.2. Владелец Сайта не гарантирует, что программное обеспечение Сайта не содержит ошибок и/или
            компьютерных вирусов или посторонних фрагментов кода. Владелец Сайта предоставляет возможность Пользователю
            пользоваться программным обеспечением Сайта «как оно есть», без каких-либо гарантий со стороны Владельца
            Сайта. 6.3. Владелец Сайта не несет ответственности за убытки, причиненные Пользователю в результате
            сообщения другим Пользователем недостоверной информации, а также причиненные действиями (бездействием)
            другого Пользователя. Владелец Сайта не гарантирует, что информация, содержащаяся в Профилях Пользователей,
            является достоверной и полной. 6.4. Владелец Сайта прилагает все возможные усилия для обеспечения нормальной
            работоспособности Сайта, однако не несет ответственности за неисполнение или ненадлежащее исполнение
            обязательств по Соглашению, а также возможные убытки, возникшие в том числе, но не ограничиваясь, в
            результате: – неправомерных действий Пользователей, направленных на нарушения информационной безопасности
            или нормального функционирования Сайта; – сбоев в работе Сайта, вызванных ошибками в коде, компьютерными
            вирусами и иными посторонними фрагментами кода в программном обеспечении Сайта; – отсутствия (невозможности
            установления, прекращения и пр.) интернет-соединений между сервером Пользователя и сервером Сайта; –
            проведения государственными и муниципальными органами, а также иными организациями мероприятий в рамках
            Системы оперативно-разыскных мероприятий; – установления государственного регулирования (или регулирования
            иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления
            указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение Соглашения; –
            других случаев, связанных с действиями (бездействием) Пользователей и/или других субъектов, направленными на
            ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования, существовавшей на
            момент заключения Соглашения, а также любых других действий, направленных на Сайт и на третьих лиц; –
            выполнения работ, указанных в пунктах 6.5 и 6.6 настоящего Соглашения. 6.5. Владелец Сайта имеет право
            производить профилактические работы в программно-аппаратном комплексе Сайта с временным приостановлением
            работы Сайта по возможности в ночное время и максимально сокращая время неработоспособности Сайта, уведомляя
            об этом Пользователя, если технически это представляется возможным. 6.6. В случае наступления форс-мажорных
            обстоятельств, а также аварий или сбоев в программно-аппаратных комплексах третьих лиц, сотрудничающих с
            Владельцем Сайта, или действий (бездействия) третьих лиц, направленных на приостановку или прекращение
            функционирования Сайта, возможна приостановка работы Сайта без предварительного уведомления Пользователя.
            6.7. Владелец Сайта не несет ответственности за нарушение Пользователем настоящих Правил и оставляет за
            собой право по своему собственному усмотрению, а также при получении информации от других пользователей либо
            третьих лиц о нарушении Пользователем настоящих Правил изменять (модерировать) или удалять любую публикуемую
            Пользователем информацию, нарушающую запреты, установленные настоящими Правилами (включая личные сообщения),
            приостанавливать, ограничивать или прекращать доступ Пользователя ко всем или к любому из разделов или
            сервисов Сайта в любое время по любой причине или без объяснения причин, с предварительным уведомлением или
            без такового, не отвечая за любой вред, который может быть причинен таким действием. Владелец Сайта
            закрепляет за собой право удалить Профиль Пользователя и (или) приостановить, ограничить или прекратить
            доступ Пользователя к любому из сервисов Сайта, если обнаружит, что, по его мнению, Пользователь
            представляет угрозу для Сайта и (или) его Пользователей. Владелец Сайта не несет ответственности за
            осуществленное в соответствии с настоящими Правилами временное блокирование или удаление информации либо
            удаление персональной страницы (прекращение регистрации) Пользователя. Удаление Профиля Пользователя
            означает автоматическое удаление всей информации, размещенной на ней, а также всей информации Пользователя,
            введенной при регистрации на Сайте. После удаления персональной страницы Пользователь теряет права доступа к
            Сайту. 6.8. Ни одна из Сторон не несет ответственности за полное или частичное неисполнение любой из своих
            обязанностей, если неисполнение является следствием таких обстоятельств, как наводнение, пожар,
            землетрясение, другие стихийные бедствия, война или военные действия и другие обстоятельства непреодолимой
            силы, возникшие после заключения Соглашения и не зависящие от воли Сторон. Порядок разрешения споров и
            урегулирования претензий 7.1. В случае возникновения споров между Пользователем и Владельцем Сайта по
            вопросам, связанным с исполнением Соглашения, Стороны примут все меры к разрешению их путем переговоров
            между собой. Претензионный порядок разрешения споров обязателен. Претензии Пользователей по предоставляемым
            Услугам принимаются и рассматриваются Владельцем Сайта только в письменном виде и в порядке, предусмотренном
            настоящим Соглашением и действующим законодательством РФ. 7.2. Для разрешения споров, возникших между
            Пользователем и Владельцем Сайта в результате использования услуг, применяется следующий претензионный
            порядок. Пользователь, считающий, что его права нарушены из-за действий Владельца Сайта, направляет
            последнему претензию, содержащую суть предъявляемого требования, обоснование его предъявления, а также все
            данные Пользователя. Претензия также направляется Владельцу Сайта в письменном виде посредством отправки по
            почте или по факсу; – в течение 5 (пяти) рабочих дней со дня получения претензии Владелец Сайта обязан
            изложить свою позицию по указанным в ней принципиальным вопросам и направить свой ответ по адресу
            электронной почты или почтовому адресу, указанному в претензии Пользователя; – в случае недостижения
            разрешения спора путем претензионного порядка спор подлежит рассмотрению в соответствии с пунктом 7.4
            Соглашения; – Владельцем Сайта не рассматриваются анонимные претензии или претензии, не позволяющие
            идентифицировать Пользователя на основе предоставленных им при регистрации данных, или претензии, не
            содержащие данных, указанных в настоящем пункте настоящего Соглашения. 7.3. Для решения технических вопросов
            при определении вины Пользователя в результате его неправомерных действий при пользовании сетью Интернет и
            Сайтом, в частности, Владелец Сайта вправе самостоятельно привлекать компетентные организации в качестве
            экспертов. В случае установления вины Пользователя последний обязан возместить затраты на проведение
            экспертизы. 7.4. При недостижении согласия между Сторонами путем переговоров спор, вытекающий из настоящего
            Соглашения, подлежит рассмотрению в суде общей юрисдикции по месту нахождения Владельца Сайта.
        </p>
    </div>
</template>

<script>
export default {
    name: "PrivacyPolicy",
    beforeRouteEnter(to, from, next) {
        document.title = to.meta.title;
        next();
    },
};
</script>

<style></style>
