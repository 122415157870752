import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";
import MainPage from "@/pages/MainPage";
import TermsOfUse from "@/pages/TermsOfUse";
import PrivacyPolicy from "@/pages/PrivacyPolicy";

const routes = [
    {
        path: "/",
        component: MainPage,
    },
    {
        path: "/terms-of-use",
        component: TermsOfUse,
        meta: {
            title: "Условия использования - LegalDozor",
        },
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
        meta: {
            title: "Политика конфиденциальности - LegalDozor",
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
