<template>
    <div>
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.5013 20.4167C19.768 20.4167 19.0346 20.2333 18.368 19.8833L7.51797 14.0333C6.53464 13.5 5.91797 12.45 5.91797 11.2833C5.91797 10.1167 6.53464 9.06667 7.51797 8.53334L18.368 2.68334C19.7013 1.96667 21.3013 1.96667 22.618 2.68334L33.468 8.53334C34.4513 9.06667 35.068 10.1167 35.068 11.2833C35.068 12.45 34.4513 13.5 33.468 14.0333L22.618 19.8833C21.968 20.25 21.2346 20.4167 20.5013 20.4167ZM20.5013 4.63334C20.1846 4.63334 19.8513 4.71667 19.5513 4.88334L8.71797 10.7167C8.43464 10.8667 8.41797 11.1833 8.41797 11.2833C8.41797 11.3833 8.43464 11.7 8.71797 11.8333L19.568 17.6833C20.168 18 20.8513 18 21.4513 17.6833L32.3013 11.8333C32.568 11.6833 32.6013 11.3667 32.6013 11.2833C32.6013 11.1833 32.5846 10.8667 32.3013 10.7333L21.4513 4.88334C21.1513 4.71667 20.818 4.63334 20.5013 4.63334Z"
                fill="#5C5CFF"
            />
            <path
                d="M16.7987 37.8667C16.332 37.8667 15.8487 37.75 15.4154 37.5333L5.31536 32.5C3.63203 31.65 2.58203 29.95 2.58203 28.0667V18.5333C2.58203 17.45 3.13203 16.4667 4.0487 15.9C4.98203 15.3333 6.0987 15.2833 7.06536 15.7667L17.1654 20.8C18.8487 21.65 19.8987 23.3333 19.8987 25.2333V34.7667C19.8987 35.85 19.3487 36.8333 18.432 37.4C17.932 37.7167 17.3654 37.8667 16.7987 37.8667ZM5.68203 17.9333C5.5487 17.9333 5.43203 17.9833 5.36536 18.0167C5.26536 18.0833 5.08203 18.2333 5.08203 18.5333V28.0667C5.08203 29 5.5987 29.85 6.43203 30.2667L16.5154 35.3167C16.782 35.45 16.9987 35.35 17.0987 35.3C17.1987 35.2333 17.382 35.0833 17.382 34.7833V25.25C17.382 24.3167 16.8654 23.4667 16.032 23.05L5.9487 18C5.8487 17.95 5.76536 17.9333 5.68203 17.9333Z"
                fill="#5C5CFF"
            />
            <path
                d="M24.1977 37.8667C23.631 37.8667 23.0643 37.7167 22.5643 37.4C21.6477 36.8333 21.0977 35.85 21.0977 34.7667V25.2333C21.0977 23.35 22.1477 21.65 23.831 20.8L33.9143 15.75C34.881 15.2667 36.0143 15.3167 36.931 15.8833C37.8477 16.45 38.3977 17.4333 38.3977 18.5167V28.05C38.3977 29.9333 37.3477 31.6333 35.6643 32.4833L25.581 37.5333C25.1477 37.7667 24.6643 37.8667 24.1977 37.8667ZM35.3143 17.9333C35.231 17.9333 35.1477 17.95 35.0477 18L24.9643 23.05C24.131 23.4667 23.6143 24.3 23.6143 25.25V34.7833C23.6143 35.0833 23.7977 35.2333 23.8977 35.3C23.9977 35.3667 24.2143 35.45 24.481 35.3167L34.5643 30.2667C35.3977 29.85 35.9143 29 35.9143 28.0667V18.5333C35.9143 18.2333 35.731 18.0833 35.631 18.0167C35.5643 17.9833 35.4477 17.9333 35.3143 17.9333Z"
                fill="#5C5CFF"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: "SourceIcon",
};
</script>

<style></style>
