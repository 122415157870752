<template>
    <div>
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.10811 5.39201C0.77226 5.39201 0.5 5.66427 0.5 6.00012C0.5 6.33597 0.77226 6.60823 1.10811 6.60823L13.4236 6.60823L9.54025 10.4916C9.30277 10.7291 9.30277 11.1141 9.54025 11.3516C9.77773 11.5891 10.1628 11.5891 10.4002 11.3516L15.3219 6.42997C15.4359 6.31593 15.5 6.16125 15.5 5.99997C15.5 5.83869 15.4359 5.68402 15.3219 5.56997L10.4002 0.648336C10.1628 0.410854 9.77773 0.410854 9.54025 0.648336C9.30277 0.885818 9.30277 1.27085 9.54025 1.50833L13.4239 5.39201L1.10811 5.39201Z"
                fill="#6867FF"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: "RightArrowIcon",
};
</script>

<style></style>
