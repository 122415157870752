<template>
    <div>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.332 36.25H26.6654C25.982 36.25 25.4154 35.6833 25.4154 35C25.4154 34.3167 25.982 33.75 26.6654 33.75H28.332C32.632 33.75 35.4154 30.9667 35.4154 26.6667V13.3333C35.4154 9.03333 32.632 6.25 28.332 6.25H11.6654C7.36536 6.25 4.58203 9.03333 4.58203 13.3333V15C4.58203 15.6833 4.01536 16.25 3.33203 16.25C2.6487 16.25 2.08203 15.6833 2.08203 15V13.3333C2.08203 7.6 5.93203 3.75 11.6654 3.75H28.332C34.0654 3.75 37.9154 7.6 37.9154 13.3333V26.6667C37.9154 32.4 34.0654 36.25 28.332 36.25Z"
                fill="#5C5CFF"
            />
            <path
                d="M20.4833 35.0999C19.8666 35.0999 19.3333 34.6333 19.25 34.0166C18.3333 26.8833 13.1333 21.6666 5.98329 20.75C5.29996 20.6666 4.81663 20.0332 4.89997 19.3499C4.9833 18.6666 5.61663 18.1833 6.29996 18.2666C14.6 19.3333 20.6666 25.3833 21.7166 33.6833C21.8 34.3666 21.3166 34.9999 20.6333 35.0832C20.6 35.0999 20.5333 35.0999 20.4833 35.0999Z"
                fill="#5C5CFF"
            />
            <path
                d="M14.8815 36.8832C14.2648 36.8832 13.7315 36.4166 13.6482 35.7999C12.9982 30.7166 9.28149 26.9999 4.19816 26.3499C3.51483 26.2665 3.03148 25.6332 3.11481 24.9499C3.19814 24.2666 3.8315 23.7832 4.51483 23.8666C10.7648 24.6666 15.3148 29.2332 16.1148 35.4665C16.1981 36.1499 15.7148 36.7832 15.0315 36.8665C14.9815 36.8832 14.9315 36.8832 14.8815 36.8832Z"
                fill="#5C5CFF"
            />
            <path
                d="M8.55233 37.95C7.93566 37.95 7.40232 37.4833 7.31898 36.8666C7.03565 34.6166 5.38566 32.9666 3.13566 32.6833C2.45233 32.6 1.96898 31.9666 2.05231 31.2833C2.13564 30.6 2.769 30.1166 3.45233 30.2C6.819 30.6333 9.36899 33.1833 9.80233 36.55C9.88566 37.2333 9.40231 37.8666 8.71898 37.95C8.66898 37.95 8.61899 37.95 8.55233 37.95Z"
                fill="#5C5CFF"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: "SourceIcon",
};
</script>

<style></style>
