<template>
    <div class="powerful-tool-block">
        <h3>Мощный и удобный инструмент</h3>
        <p class="powerful-tool-block__text secondary-text">
            Продающий текст, который я еще не придумал, но он тут точно должен быть
        </p>

        <div class="powerful-tool__cards">
            <PowerfulToolCard v-for="card in cards" :key="card.id" :title="card.title" :text="card.text">
                <component :is="card.icon" />
            </PowerfulToolCard>
        </div>
    </div>
</template>

<script>
import { markRaw } from "vue";
import PowerfulToolCard from "./PowerfulToolCard.vue";
import SourceIcon from "../icons/SourceIcon.vue";
import CollectingIcon from "../icons/CollectingIcon.vue";
import NetworkIcon from "../icons/NetworkIcon.vue";

export default {
    name: "PowerfulToolBlock",
    components: { PowerfulToolCard },

    data() {
        return {
            cards: [
                {
                    id: 0,
                    title: "Официальные источники",
                    text: "Анализ и мониторинг юридических лиц из 15 официальных источников предусмотренных законодательством",
                    icon: markRaw(SourceIcon),
                },
                {
                    id: 1,
                    title: "Сбор и анализ",
                    text: "Ежедневный анализ по действующим и ликвидированным юридическим лицам.",
                    icon: markRaw(CollectingIcon),
                },
                {
                    id: 2,
                    title: "Оповещаем онлайн",
                    text: "Каждое сведение об изменениях юридического лица обновляется  сразу и оповещает по принципу светофора",
                    icon: markRaw(NetworkIcon),
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.powerful-tool-block {
    background: #0b1a38;
    padding: 90px 0 100px;
    text-align: center;
    color: #ffffff;
    @include block-padding;

    & h3 {
        margin-bottom: 42px;
    }
}

.powerful-tool-block__text {
    margin-bottom: 64px;
}

.powerful-tool__cards {
    display: flex;
    gap: 15px;
}

@media screen and (max-width: 767.99px) {
    .powerful-tool__cards {
        flex-direction: column;

        & > div {
            width: 100%;
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}

@media screen and (max-width: 767.99px) {
    .powerful-tool__cards {
        flex-direction: column;

        & > div {
            width: 100%;
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}

@media screen and (max-width: 499.99px) {
    .powerful-tool__cards {
        & > div {
            width: 100%;
            padding-left: 42px;
            padding-right: 42px;
        }
    }
}
</style>
