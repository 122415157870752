<template>
    <div class="main-block" id="main">
        <LogoComponent />
        <div class="main-block__content">
            <div class="main-block__text-block">
                <h1 class="main-block__title">Работайте только с надежными партнерами</h1>

                <p class="main-block__paragraph">
                    Новый стандарт коммерческой осмотрительности для&nbsp;оценки и мониторинга состояния контрагентов
                    по&nbsp;15&nbsp;источникам в соответствие ст. 54.1 НК РФ
                </p>

                <div class="main-block__buttons">
                    <a href="https://cabinet.legaldozor.ru" target="_blank">
                        <ButtonUI text="Попробовать бесплатно" />
                    </a>

                    <a href="https://cabinet.legaldozor.ru" target="_blank">
                        <ButtonUI type="ghost" text="Посмотрeть демо">
                            <RightArrowIcon />
                        </ButtonUI>
                    </a>
                </div>
            </div>

            <div class="main-block__image-block">
                <img src="../images/main-image.webp" />
            </div>
        </div>

        <div class="main-block__icons-slider">
            <p>Государственные источники Legal Dozor</p>

            <div class="icons-slider__content">
                <div>
                    <img src="../images/organizations/fedresurs.png" />
                </div>
                <div>
                    <img src="../images/organizations/cbrf.png" />
                </div>
                <div>
                    <img src="../images/organizations/rkn.png" />
                </div>
                <div>
                    <img src="../images/organizations/rospatent.png" />
                </div>
                <div>
                    <img src="../images/organizations/fedkazn.png" />
                </div>
                <div>
                    <img src="../images/organizations/fednotar.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosfinmon.png" />
                </div>
                <div>
                    <img src="../images/organizations/rpn.png" />
                </div>
                <div>
                    <img src="../images/organizations/roszdrav.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosteh.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosalko.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosacc.png" />
                </div>
                <div>
                    <img src="../images/organizations/fns.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosstat.png" />
                </div>

                <div>
                    <img src="../images/organizations/fedresurs.png" />
                </div>
                <div>
                    <img src="../images/organizations/cbrf.png" />
                </div>
                <div>
                    <img src="../images/organizations/rkn.png" />
                </div>
                <div>
                    <img src="../images/organizations/rospatent.png" />
                </div>
                <div>
                    <img src="../images/organizations/fedkazn.png" />
                </div>
                <div>
                    <img src="../images/organizations/fednotar.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosfinmon.png" />
                </div>
                <div>
                    <img src="../images/organizations/rpn.png" />
                </div>
                <div>
                    <img src="../images/organizations/roszdrav.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosteh.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosalko.png" />
                </div>
                <div>
                    <img src="../images/organizations/rosacc.png" />
                </div>
                <div>
                    <img src="../images/organizations/fns.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RightArrowIcon from "./icons/RightArrowIcon.vue";
import ButtonUI from "./ui/ButtonUI.vue";
import FedresursIcon from "../components/icons/organizations/FedresursIcon.vue";
import LogoComponent from "./LogoComponent.vue";

export default {
    name: "MainBlock",
    components: { ButtonUI, RightArrowIcon, FedresursIcon, LogoComponent },
};
</script>

<style lang="scss">
@import "../assets/scss/mixins.scss";

.main-block {
    padding: 60px 0 100px;
    @include block-padding;

    & .logo-wrap {
        margin-bottom: 20px;
        display: none;
    }
}

.main-block__content {
    display: flex;
    margin-bottom: 64px;
}

.main-block__text-block {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.main-block__title {
    margin-bottom: 41px;
    width: 480px;
}

.main-block__paragraph {
    margin-bottom: 64px;
}

.main-block__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .button {
        flex-direction: row-reverse;
    }
}

.main-block__image-block {
    img {
        width: 100%;
    }
}

.main-block__icons-slider {
    overflow: hidden;

    & p {
        margin-bottom: 32px;
    }
}

.icons-slider__content {
    display: flex;

    gap: 24px;
    animation: slider-animation 25s linear infinite;

    & div {
        width: 70px;

        & img {
            width: 70px;
            height: auto;
        }
    }
}

@keyframes slider-animation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-1200px - 115px)); /* Смещение на 100% ширины контейнера */
    }
}

@media screen and (max-width: 1023.99px) {
    .main-block {
        padding-top: 10px;

        & .logo-wrap {
            display: block;
        }
    }
}

@media screen and (max-width: 860px) {
    .main-block__image-block {
        padding-top: 80px;
    }
}

@media screen and (max-width: 675px) {
    .main-block__image-block {
        display: none;
    }

    .main-block__text-block {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .main-block__title {
        margin-bottom: 41px;
        width: 100%;
    }
}
</style>
