<template>
    <div class="powerful-tool-card">
        <div class="powerful-tool-card__icon">
            <slot />
        </div>

        <p class="powerful-tool-card__title">{{ title }}</p>
        <p class="powerful-tool-card__text secondary-text">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: "PowerfulToolCard",
    props: {
        title: {
            type: String,
            default: "Заголовок",
            required: true,
        },
        text: {
            type: String,
            default: "Рыбный текст абазаца по умолчанию",
            required: true,
        },
    },
};
</script>

<style lang="scss">
.powerful-tool-card {
    width: 390px;
    min-height: 280px;
    background: #182949;
    border-radius: 20px;
    padding: 42px 32px;
    flex-shrink: 1;
}

.powerful-tool-card__icon {
    margin-bottom: 24px;
}

.powerful-tool-card__title {
    font-weight: 600;
    margin-bottom: 24px;
}

.powerful-tool-card__text {
    font-size: 14px;
}
</style>
