<template>
    <div>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6154 37.9167H7.6987C3.86536 37.9167 2.08203 36.1333 2.08203 32.3V24.3833C2.08203 20.5333 3.86536 18.75 7.6987 18.75H11.6654C12.3487 18.75 12.9154 19.3167 12.9154 20V23.95C12.9154 26.3833 13.6154 27.0833 16.032 27.0833H19.9987C20.682 27.0833 21.2487 27.65 21.2487 28.3333V32.3C21.2487 36.1333 19.4654 37.9167 15.6154 37.9167ZM7.6987 21.25C5.28203 21.25 4.58203 21.95 4.58203 24.3833V32.3C4.58203 34.7167 5.28203 35.4167 7.6987 35.4167H15.6154C18.0487 35.4167 18.7487 34.7167 18.7487 32.3V29.5833H16.032C12.1987 29.5833 10.4154 27.8 10.4154 23.95V21.25H7.6987Z"
                fill="#5C5CFF"
            />
            <path
                d="M23.9513 29.5833H16.0346C12.2013 29.5833 10.418 27.8 10.418 23.95V16.0333C10.418 12.2 12.2013 10.4167 16.0346 10.4167H20.0013C20.6846 10.4167 21.2513 10.9833 21.2513 11.6667V15.6167C21.2513 18.05 21.9513 18.75 24.368 18.75H28.3346C29.018 18.75 29.5846 19.3167 29.5846 20V23.95C29.5846 27.8 27.8013 29.5833 23.9513 29.5833ZM16.0346 12.9167C13.618 12.9167 12.918 13.6167 12.918 16.0333V23.95C12.918 26.3833 13.618 27.0833 16.0346 27.0833H23.9513C26.3846 27.0833 27.0846 26.3833 27.0846 23.95V21.25H24.368C20.5346 21.25 18.7513 19.4667 18.7513 15.6167V12.9167H16.0346Z"
                fill="#5C5CFF"
            />
            <path
                d="M32.2833 21.25H24.3667C20.5333 21.25 18.75 19.4667 18.75 15.6167V7.7C18.75 3.86667 20.5333 2.08333 24.3667 2.08333H32.2833C36.1333 2.08333 37.9167 3.86667 37.9167 7.7V15.6167C37.9167 19.4667 36.1333 21.25 32.2833 21.25ZM24.3667 4.58333C21.95 4.58333 21.25 5.28333 21.25 7.7V15.6167C21.25 18.05 21.95 18.75 24.3667 18.75H32.2833C34.7167 18.75 35.4167 18.05 35.4167 15.6167V7.7C35.4167 5.28333 34.7167 4.58333 32.2833 4.58333H24.3667Z"
                fill="#5C5CFF"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: "CollectingIcon",
};
</script>

<style></style>
