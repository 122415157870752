<template>
    <button class="button" :class="classList">
        <i v-if="loading" class="el-icon-loading" />
        <slot v-else />
        <span v-if="!onlyIcon" class="button-text">{{ text }}</span>
    </button>
</template>

<script>
export default {
    name: "ButtonUI",
    props: {
        text: {
            type: String,
            default: "Текст по умолчанию",
        },
        type: {
            type: String,
            default: "primary",
            validator: (value) =>
                ["primary", "secondary", "tertiary", "danger", "ghost", "ghost-danger", "grey"].includes(value),
        },
        size: {
            type: String,
            default: "large",
            validator: (value) => ["large", "medium"].includes(value),
        },
        onlyIcon: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            sizeClasses: {
                large: "button-large",
                medium: "button-medium",
            },
            typeClasses: {
                primary: "button-primary",
                secondary: "button-secondary",
                tertiary: "button-tertiary",
                danger: "button-danger",
                ghost: "button-ghost",
                "ghost-danger": "button-ghost-danger",
                grey: "button-grey",
            },
            onlyIconClasses: {
                large: "button-only-icon-large",
                medium: "button-only-icon-medium",
            },
        };
    },

    computed: {
        classList() {
            const classList = [];
            classList.push(
                this.sizeClasses[this.size],
                this.typeClasses[this.type],
                this.onlyIcon ? this.onlyIconClasses[this.size] : "",
                this.loading ? "button-disabled" : "",
                this.disabled ? "button-disabled" : ""
            );
            return classList;
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.button {
    /* @include flex-jc-ac; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    font-weight: 500;
    color: #ffffff;
    border: 1px solid transparent;
    cursor: pointer;
    gap: 10px;

    & div svg path {
        fill: #ffffff;
    }
}

.button-text {
    white-space: nowrap;
}

.button-large {
    font-size: 16px;
    padding: 0 24px;
    height: 48px;
    & div {
        width: 100%;
        & svg {
            width: 16px;
        }
    }
}
.button-only-icon-large {
    width: 48px;
}

.button-medium {
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
    & div {
        width: 100%;
        & svg {
            width: 13px;
        }
    }
}
.button-only-icon-medium {
    width: 40px;
}

.button-primary {
    background: $buttonPrimaryColor;
    &:hover {
        background: $buttonPrimaryHoverColor;
    }
    &:active {
        background: $buttonPrimaryPressedColor;
    }
}

.button-secondary {
    background: $buttonSecondaryColor;
    color: $textActionColor;
    & div svg path {
        fill: $textActionColor;
    }
    &:hover {
        background: $buttonSecondaryHoverColor;
        color: #ffffff;
        & div svg path {
            fill: #ffffff;
        }
    }
    &:active {
        background: $buttonSecondaryPressedColor;
    }
}

.button-tertiary {
    background: #ffffff;
    border: 1px solid $borderPrimaryColor;
    color: $textActionColor;
    & div svg path {
        fill: $textActionColor;
    }
    &:hover {
        background: $buttonSecondaryHoverColor;
        color: #ffffff;
        border: 1px solid transparent;
        & div svg path {
            fill: #ffffff;
        }
    }
    &:active {
        background: $buttonPrimaryPressedColor;
    }
}

.button-danger {
    background: $buttonDangerColor;
    &:hover {
        background: $buttonDangerHoverColor;
        color: #ffffff;
        border: 1px solid transparent;
    }
    &:active {
        background: $buttonDangerPressedColor;
    }
}

.button-ghost {
    background: none;
    color: $textActionColor;
    & div svg path {
        fill: $textActionColor;
    }
    &:hover {
        background: $buttonGhostHoverColor;
    }
    &:active {
        background: $buttonGhostPressedColor;
    }
}

.button-ghost-danger {
    color: $textErrorColor;
    background: none;
    &:hover {
        background: $buttonDangerHoverColor;
        color: #ffffff;
        border: 1px solid transparent;
    }
    &:active {
        background: $buttonDangerPressedColor;
    }
}

.button-grey {
    background: none;
    color: $iconSecondaryColor;
    & div svg path {
        fill: $iconSecondaryColor;
    }
    &:hover {
        background: $buttonGreyHoverColor;
        & div svg path {
            fill: $iconPrimaryColor;
        }
    }
}

.button-disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>
