<template>
    <div class="collecting-block" id="collecting">
        <div class="collecting-block__content">
            <h3>Сбор и анализ</h3>
            <p class="collecting-block__text">
                Сервис Legal Dozor собирает и анализирует информацию по всем зарегистрированным юридическим лицам —
                действующим и ликвидированным.
            </p>

            <div class="icon-list">
                <IconListElement v-for="el in iconList" :key="el.id" :text="el.text" :icon="el.icon" />
            </div>

            <div class="text-link-wrap">
                <a href="https://cabinet.legaldozor.ru" target="_blank">
                    <span class="text-link">Посмотреть онбординги</span>
                </a>

                <RightArrowIcon />
            </div>

            <ReviewCard :text="reviewText" name="Коля Треногин" job="Product Marketing Manager">
                <template #avatar>
                    <img src="../../images/review-avatars/trenogin.svg" />
                </template>
                <template #company>
                    <img src="../../images/review-companies/get-response.svg" />
                </template>
            </ReviewCard>
        </div>

        <div class="collecting-block__image">
            <img src="../../images/collecting-image.webp" />
        </div>
    </div>
</template>

<script>
import IconListElement from "../IconListElement.vue";
import RightArrowIcon from "../icons/RightArrowIcon.vue";
import ChartIcon from "@/images/icons/chart.svg";
import BookIcon from "@/images/icons/book.svg";
import MoneyIcon from "@/images/icons/money.svg";
import ReviewCard from "@/components/ReviewCard.vue";

export default {
    name: "CollectingBlock",
    components: { IconListElement, RightArrowIcon, ReviewCard },

    data() {
        return {
            iconList: [
                {
                    id: 0,
                    text: "Виды деятельности по ОКВЭД",
                    icon: ChartIcon,
                },
                {
                    id: 1,
                    text: "Бухгалтерскую отчетность",
                    icon: BookIcon,
                },
                {
                    id: 2,
                    text: "Торговые марки",
                    icon: MoneyIcon,
                },
            ],

            reviewText:
                "“Я очень доволен сервисом Legal Dozor. Сервис работает быстро и надежно, а интерфейс удобен и понятен. Благодаря Legal Dozor я смог избежать рисков и проблем, связанных с недобросовестными субъектами. Рекомендую всем, кто занимается бизнесом или юридической деятельностью.”",
        };
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.collecting-block {
    padding: 90px 0 100px;
    @include block-padding;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    & h3 {
        margin-bottom: 32px;
    }

    & .collecting-block__text {
        margin-bottom: 24px;
    }

    & .icon-list {
        margin-bottom: 22px;
    }

    & .text-link-wrap {
        margin-bottom: 46px;
    }
}

.collecting-block__image {
    & img {
        max-width: 100%;
    }
}

@media screen and (max-width: 676px) {
    .collecting-block__image {
        display: none;
    }

    .collecting-block__content {
        & .review-card {
            width: 100%;
        }
    }
}
</style>
