<template>
    <div class="nalog-block">
        <div class="nalog-block__content">
            <p class="nalog-block__subtitle">Налоговый кодекс Российской Федерации</p>

            <div class="nalog-block__text-wrap">
                <p>Закон обязывает проверять на&nbsp;благонадежность контрагента и&nbsp;их&nbsp;учредителей</p>
                <div class="nalog-block__link">
                    <p>Ст. 54.1 НК РФ</p>
                    <RightArrowIcon />
                </div>
            </div>

            <img src="../../images/nalog-book.webp" />
        </div>
    </div>
</template>

<script>
import RightArrowIcon from "@/components/icons/RightArrowIcon.vue";

export default {
    name: "NalogBlock",
    components: { RightArrowIcon },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.nalog-block {
    padding: 90px 0 100px;
    @include block-padding;

    & p {
        color: #ffffff;
    }
}

.nalog-block__content {
    position: relative;
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(48deg, #5c5cff 0%, #8960ff 98%);
    box-shadow: 0px 20px 20px -3px rgba(126, 137, 169, 0.15);
    padding: 28px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & img {
        position: absolute;
        width: 301px;
        bottom: 0;
        right: 7%;
    }
}

.nalog-block__subtitle {
    font-size: 12px;
    margin-bottom: 42px;
}

.nalog-block__text-wrap {
    display: flex;
    align-items: center;
    gap: 50px;

    & > p {
        font-weight: 600;
        width: 36%;
    }
}

.nalog-block__link {
    display: flex;
    gap: 8px;

    & p {
        font-weight: 600;
    }

    & div svg path {
        fill: #ffffff;
    }
}

@media screen and (max-width: 1023.99px) {
    .nalog-block__text-wrap {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        & > p {
            width: 45%;
        }
    }

    .nalog-block__subtitle {
        margin-bottom: 24px;
    }

    .nalog-block__content img {
        right: 4%;
    }
}

@media screen and (max-width: 727.99px) {
    .nalog-block__content img {
        width: 186px;
        right: 10%;
    }
}

@media screen and (max-width: 535.99px) {
    .nalog-block__content {
        padding: 28px 36px;

        & img {
            width: 37%;
            right: 10px;
        }
    }
}

@media screen and (max-width: 429.99px) {
    .nalog-block__text-wrap {
        & > p {
            width: 278px;
        }
    }

    .nalog-block__content img {
        width: 25%;
        right: 30px;
    }
}
</style>
