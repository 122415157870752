<template>
    <HeaderComponent />

    <MainBlock />
    <PowerfulToolBlock />
    <NotificationsBlock />
    <CollectingBlock />
    <SourcesBlock />
    <NalogBlock />
    <TariffBlock />
    <StartBlock />
    <StartActionBlock />

    <FooterComponent />
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import MainBlock from "@/components/MainBlock.vue";
import PowerfulToolBlock from "@/components/powerful-tool-block/PowerfulToolBlock.vue";
import NotificationsBlock from "@/components/notifications-block/NotificationsBlock.vue";
import CollectingBlock from "@/components/collecting-block/CollectingBlock.vue";
import SourcesBlock from "@/components/sources-block/SourcesBlock.vue";
import NalogBlock from "@/components/nalog-block/NalogBlock.vue";
import TariffBlock from "@/components/tariff-block/TariffBlock.vue";
import StartBlock from "@/components/start-block/StartBlock.vue";
import StartActionBlock from "@/components/start-action-block/StartActionBlock.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
    name: "MainPage",
    components: {
        HeaderComponent,
        MainBlock,
        PowerfulToolBlock,
        NotificationsBlock,
        CollectingBlock,
        SourcesBlock,
        NalogBlock,
        TariffBlock,
        StartBlock,
        StartActionBlock,
        FooterComponent,
    },
};
</script>
