<template>
    <div class="tariff-card" :class="{ 'tariff-card__highlighted': highlighted }">
        <div class="tariff-card__duration-block">
            <p class="tariff-card__duration">{{ duration }}</p>
            <p v-if="highlighted" class="tariff-card__text">{{ text }}</p>
        </div>
        <div class="tariff-card__price-block">
            <h3 class="tariff-card__new-price">{{ newPrice }}</h3>
            <h3 v-if="!highlighted" class="tariff-card__old-price">{{ oldPrice }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: "TariffCard",
    props: {
        highlighted: {
            type: Boolean,
            defalult: false,
        },
        text: {
            type: String,
            defalult: "Рыбный текст описания",
        },
        newPrice: {
            type: String,
            defalult: "0 0000 ₽",
            required: true,
        },
        oldPrice: {
            type: String,
            defalult: "0 0000 ₽",
        },
        duration: {
            type: String,
            defalult: "0 месяцев",
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.tariff-card {
    width: 480px;
    min-height: 164px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    padding: 24px;
}

.tariff-card__duration {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.tariff-card__highlighted {
    background: #ffffff;
    border-radius: 10px;
    align-items: flex-start;
    box-shadow: 0px 2px 13px -1px #c0c6d961;
    border: 0 solid transparent !important;

    & .tariff-card__duration {
        margin-bottom: 16px;
    }
}

.tariff-card__price-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tariff-card__text {
    font-size: 14px;
}

.tariff-card__new-price {
    font-weight: 700;
    color: #595a66;
    white-space: nowrap;
}

.tariff-card__old-price {
    font-size: 24px;
    font-weight: 500;
    position: relative;
    color: #8b8e99;
    align-self: flex-end;

    &::after {
        content: "";
        position: absolute;
        bottom: 50%;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $systemErrorColor;
        transform: skewY(-4deg);
    }
}
</style>
