<template>
    <div class="collecting-block sources-block" id="sources">
        <div class="collecting-block__content">
            <h3>Более 15 официальных источников</h3>
            <p class="collecting-block__text">
                Legal Dozor сервис автоматизированной проверки и мониторинга изменений юридических лиц по 15 источникам.
            </p>

            <div class="icon-list">
                <IconListElement v-for="el in iconList" :key="el.id" :text="el.text" :icon="el.icon" />
            </div>

            <div class="text-link-wrap">
                <a href="https://cabinet.legaldozor.ru" target="_blank">
                    <span class="text-link">Посмотреть все</span>
                </a>

                <RightArrowIcon />
            </div>

            <ReviewCard :text="reviewText" name="Коля Треногин" job="Product Marketing Manager">
                <template #avatar>
                    <img src="../../images/review-avatars/trenogin.svg" />
                </template>
                <template #company>
                    <img src="../../images/review-companies/get-response.svg" />
                </template>
            </ReviewCard>
        </div>

        <div class="collecting-block__image">
            <img src="../../images/sources-image.webp" />
        </div>
    </div>
</template>

<script>
import IconListElement from "../IconListElement.vue";
import RightArrowIcon from "../icons/RightArrowIcon.vue";
import FasIcon from "@/images/icons/fas.svg";
import FedkazIcon from "@/images/icons/fedkaz.svg";
import RknIcon from "@/images/icons/rkn.svg";
import ReviewCard from "@/components/ReviewCard.vue";

export default {
    name: "SourcesBlock",
    components: { IconListElement, RightArrowIcon, ReviewCard },

    data() {
        return {
            iconList: [
                {
                    id: 0,
                    text: "Федеральная Антимонопольная Служба",
                    icon: FasIcon,
                },
                {
                    id: 1,
                    text: "Федеральное Казначейство РФ",
                    icon: FedkazIcon,
                },
                {
                    id: 2,
                    text: "Роскомнадзор",
                    icon: RknIcon,
                },
            ],

            reviewText:
                "“Я очень доволен сервисом Legal Dozor. Сервис работает быстро и надежно, а интерфейс удобен и понятен. Благодаря Legal Dozor я смог избежать рисков и проблем, связанных с недобросовестными субъектами. Рекомендую всем, кто занимается бизнесом или юридической деятельностью.”",
        };
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.sources-block {
    flex-direction: row-reverse;

    & .collecting-block__content {
        width: 500px;
    }
}

@media screen and (max-width: 676px) {
    .collecting-block.sources-block {
        flex-direction: row;
    }
}
</style>
