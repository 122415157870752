<template>
    <div class="notifications-block" id="notifications">
        <div class="notifications-block__title-column">
            <div class="notifications-block__changing-text-wrap">
                <h1>Оповещаем <span class="highlighted-text">в&nbsp;телеграм</span></h1>
                <h1>Оповещаем <span class="highlighted-text">по&nbsp;телефону</span></h1>
                <h1>Оповещаем <span class="highlighted-text">по&nbsp;email</span></h1>
            </div>

            <img class="notifications-image-1" src="../../images/notifications-image.svg" />
        </div>

        <div class="notifications-block__content-column">
            <div class="notifications-block__text">
                <p>
                    Legal Dozor оповещает об изменениях юридических лиц в режиме реального времени в удобном вам формате
                    .
                </p>
                <p>
                    Каждое сведение об изменениях юридического лица обновляется сразу и&nbsp;оповещает по принципу
                    светофора.
                </p>
            </div>

            <div class="icon-list">
                <IconListElement v-for="el in iconList" :key="el.id" :text="el.text" :icon="el.icon" />
            </div>

            <a href="https://cabinet.legaldozor.ru" target="_blank">
                <ButtonUI text="Попробовать бесплатно" />
            </a>

            <img class="notifications-image-2" src="../../images/notifications-image.svg" />
        </div>
    </div>
</template>

<script>
import IconListElement from "../IconListElement.vue";
import ButtonUI from "../ui/ButtonUI.vue";
import TelegramIcon from "@/images/icons/telegram.svg";
import PhoneIcon from "@/images/icons/phone.svg";
import MailIcon from "@/images/icons/mail.svg";

export default {
    name: "NotificationsBlock",
    components: { IconListElement, ButtonUI },

    data() {
        return {
            iconList: [
                {
                    id: 0,
                    text: "Telegram",
                    icon: TelegramIcon,
                },
                {
                    id: 1,
                    text: "мобильный телефон",
                    icon: PhoneIcon,
                },
                {
                    id: 2,
                    text: "Электронная почта",
                    icon: MailIcon,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.notifications-block {
    padding: 90px 0 100px;
    @include block-padding;
    background: #f5f7fa;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.notifications-block__title-column {
    position: relative;
    padding-top: 70px;
    overflow: hidden;

    & h1 {
        position: absolute;
        animation: changeText 6s infinite;
    }

    h1:nth-child(1) {
        animation-delay: 0s;
    }
    h1:nth-child(2) {
        opacity: 0;
        animation-delay: 2s;
    }
    h1:nth-child(3) {
        opacity: 0;
        animation-delay: 4s;
    }
}

.notifications-image-1 {
    position: absolute;
    top: 70px;
    left: 150px;
}

.notifications-image-2 {
    position: absolute;
    display: none;
}

.notifications-block__changing-text-wrap {
    position: relative;
    width: 500px;
}

@keyframes changeText {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    20% {
        opacity: 1;
    }
    33% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.notifications-block__content-column {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & p:first-child {
        margin-bottom: 12px;
    }

    & .button {
        align-self: flex-start;
    }
}

@media screen and (max-width: 989.99px) {
    .notifications-image-1 {
        top: 80px;
        left: 100px;
    }
}

@media screen and (max-width: 899.99px) {
    .notifications-block {
        grid-template-columns: 1fr;
    }

    .notifications-block__title-column {
        padding-top: 0;
        height: 150px;
    }

    .notifications-image-1 {
        display: none;
    }

    .notifications-image-2 {
        display: block;
        right: 30px;
        bottom: 0px;
        width: 200px;
    }

    .notifications-block__text {
        width: 500px;
    }
}

@media screen and (max-width: 499.99px) {
    .notifications-block__text {
        width: 100%;
    }

    .notifications-image-2 {
        right: 0px;
        bottom: 20%;
        width: 30%;
    }
}
</style>
