<template>
    <footer class="footer">
        <LogoComponent style="margin-bottom: 72px" />

        <div class="footer__content">
            <div class="footer__content-column">
                <p class="footer__column-title">Быстрые ссылки</p>
                <p class="footer__column-link">Партнеры</p>
                <p class="footer__column-link">Реклама</p>
                <p class="footer__column-link">Документы</p>
                <p class="footer__column-link">Компания</p>
                <p class="footer__column-link">Оплата</p>
            </div>

            <div class="footer__content-column">
                <p class="footer__column-title">Поддержка</p>
                <p class="footer__column-link">Триальный период</p>
                <p class="footer__column-link">Центр поддержки</p>
                <p class="footer__column-link">Частный доступ</p>
                <p class="footer__column-link">API</p>
            </div>

            <div class="footer__content-column">
                <p class="footer__column-title">Наши соцсети</p>

                <p class="footer__column-link">
                    <a href="https://vk.com/legaldozor" target="_blank">Вконтакте</a>
                </p>
                <p class="footer__column-link">
                    <a href="https://dzen.ru/legaldozor" target="_blank">Яндекс Дзен</a>
                </p>
                <p class="footer__column-link">
                    <a href="https://t.me/legaldozor" target="_blank">Telegram</a>
                </p>
                <p class="footer__column-link">
                    <a href="https://www.instagram.com/legaldozor/" target="_blank">Instagram</a>
                </p>
            </div>

            <div class="footer__content-column">
                <p class="footer__column-title">Подпишись на новости</p>
                <p>Получай все актуальные нововведния и новости сервиса</p>

                <div class="email-input__wrap">
                    <input class="email-input" placeholder="Ваш email" />
                    <ButtonUI text="Быть в курсе" />
                </div>
            </div>
        </div>

        <div class="footer__credits-wrap">
            <p>© 2023, ООО «Программные Технологии», ИНН: 5029278819</p>

            <div class="footer__rules-wrap">
                <div>
                    <p @click="$router.push('/terms-of-use')">Условия использования</p>
                </div>
                <div>
                    <p @click="$router.push('/privacy-policy')">Политика конфиденциальности</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import LogoComponent from "./LogoComponent.vue";
import ButtonUI from "@/components/ui/ButtonUI.vue";

export default {
    name: "FooterComponent",
    components: { LogoComponent, ButtonUI },
};
</script>

<style lang="scss">
.footer {
    padding: 120px 0 42px;
    max-width: 1300px;
    margin: 0 auto;
}

.footer__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)) 354px;
    row-gap: 72px;
    padding-bottom: 72px;
    border-bottom: 1px solid #b4b6be;
    margin-bottom: 72px;
}

.footer__content-column {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & .footer__column-link {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
}

.footer__column-title {
    font-weight: 600;
}

.email-input__wrap {
    position: relative;

    .button {
        font-size: 12px;
        font-weight: 600;
        padding: 8px 16px;
        height: 32px;
        border-radius: 8px;
        position: absolute;
        right: 8px;
        top: 9px;
    }
}

.email-input {
    width: 100%;
    height: 50px;
    padding: 8px 8px 8px 16px;
    border-radius: 12px;
    outline: none;
    border: none;
    background: #f7f7fe;
    font-size: 12px;

    &::placeholder {
        color: #8b8e99;
    }
}

.footer__credits-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;

    & p {
        color: #8b8e99;
    }

    /* & > p {
        width: 400px;
    } */
}

.footer__rules-wrap {
    display: flex;
    flex-wrap: wrap;

    & p {
        cursor: pointer;
        white-space: nowrap;

        &:hover {
            opacity: 0.7;
        }
    }

    & div:not(:last-child) {
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1.5px solid #b4b6be;
    }
}

@media screen and (max-width: 1339.99px) {
    .footer {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 916px) {
    .footer__content {
        grid-template-columns: repeat(auto-fit, minmax(250px, 354px));
    }
}

@media screen and (max-width: 767.99px) {
    .footer__content {
        grid-template-columns: 220px 1fr;
    }
}

@media screen and (max-width: 530px) {
    .footer__content {
        grid-template-columns: 1fr;
    }
}
</style>
